<template>
  <div class="card">
    <header class="card-header has-background-primary">
      <p class="card-header-title has-text-light is-uppercase">
        TAMBAH {{ headerText }}
      </p>
    </header>
    <div class="card-content events-card">
      <b-field class="field-spacer"></b-field>
      <div class="notification is-info is-light">
        Pastikan data yang akan diinputkan belum ada di database aplikasi. Cek
        terlebih dahulu dengan cara kembali ke halaman sebelumnya dan mengisi
        (minimal) tiga huruf untuk menampilkan data yang sudah ada di sistem.
      </div>
      <b-field class="field-spacer"></b-field>

      <template v-if="errorMap.non_field_errors">
        <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
        <b-field class="field-spacer"></b-field>
      </template>

      <b-field
        label="Nama"
        custom-class="is-small"
        :type="{ 'is-danger': errorMap.nama }"
        :message="errorMap.nama"
      >
        <b-input
          v-model="komponen.nama"
          required
          @input="validateInput('nama')"
        ></b-input>
      </b-field>

      <slot />
      <!-- untuk tambahan data terkait komponen penyakit -->

      <!-- perlu diganti dengan div spacing -->
      <div class="field is-horizontal">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
      </div>
      <div class="buttons">
        <b-button
          class="is-primary has-text-weight-semibold"
          @click.stop="cancel"
          >Batal</b-button
        >
        <b-button
          class="is-primary has-text-weight-semibold"
          :disabled="!isValid"
          @click.stop.prevent="addKomponen"
          >Simpan</b-button
        >
      </div>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import { mapActions } from "vuex";
import { has } from "lodash";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";

export default {
  name: "KomponenPenyakitCreate",
  mixins: [onlineCheckMixin], // tidak perlu fetch data karena hanya create.
  props: ["headerText", "jenisKomponen", "komponenMdl"],
  data() {
    return this.komponenMdl.getObservables();
  },
  computed: {
    isValid() {
      return this.validity.edited && this.validity.validated;
    },
  },
  methods: {
    ...mapActions("aktivitas", ["setAktKomponen"]),
    ...mapActions("karyailmiah", ["setKaryaIlmiah"]),
    validateInput(field) {
      this.komponenMdl.validate(field);
    },
    setKomponenId(respData) {
      if (has(this.$route.params, "aktKomponen")) {
        if (has(this.$route.params.aktKomponen, "komponen")) {
          this.$route.params.aktKomponen["komponen"] = respData.id;
        } else {
          this.$route.params.aktKomponen["keterampilan"] = respData.id;
        }
        this.$route.params.aktKomponen["edited"] = true;
      } else if (has(this.$route.params, "karyaIlmiah")) {
        this.$route.params.karyaIlmiah["diagnosis"] = respData.id;
        this.$route.params.karyaIlmiah["edited"] = true;
      }
      this.cancel();
    },
    addKomponen() {
      let komponenPath = this.jenisKomponen;
      const apiURL = `${APP_CONFIG.baseAPIURL}/penyakit/${komponenPath}/`;
      this.komponenMdl.setApiURL(apiURL);
      this.komponenMdl.save("create", this.setKomponenId);
    },
    cancel() {
      if (has(this.$route.params, "aktKomponen")) {
        let aktKomponen = this.$route.params.aktKomponen;
        this.setAktKomponen(aktKomponen);
      } else if (has(this.$route.params, "karyaIlmiah")) {
        let karyaIlmiah = this.$route.params.karyaIlmiah;
        this.setKaryaIlmiah(karyaIlmiah);
      }
      this.$router.go(-1);
    },
  },
  watch: {
    komponen: {
      // komponen berubah, update edited
      handler() {
        this.komponenMdl.clearNonFieldErrors();
        this.komponenMdl.setEdited(true);
      },
      deep: true,
    },
  },
};
</script>
